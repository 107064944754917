import * as React from 'react'
import { graphql, Link } from 'gatsby'
import moment from 'moment'
import Layout from '../components/layout'
import Header from '../components/header'
import Container from '../components/container'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const PageHeader = <Header title="News" />
const pagePath = '/en/news/'

const NewsPage = ({ data, pageContext, location }) => {
  const allNews = data.allContentfulAktuelles
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '' : `${currentPage - 1}/`
  const nextPage = `${currentPage + 1}/`

  return (
    <Layout
      translatedPageLinks={[
        {
          code: 'de',
          link: location.pathname.replace(pagePath, '/de/aktuelles/'),
        },
        { code: 'en', link: location.pathname },
      ]}
      pageLang="en"
      pageTitle="News"
      pageHeader={PageHeader}
      location={location}
    >
      <Container>
        <section>
          <div className="news-list">
            {allNews.nodes.map((article) => {
              const publishedAt = moment(article.publishedAt).format(
                'DD.MM.YYYY'
              )

              return (
                <Link
                  className="article"
                  key={article.slug}
                  to={pagePath + article.slug + '/'}
                >
                  <div className="date">
                    <h5>{publishedAt}</h5>
                  </div>

                  <div className="title">
                    <h3>{article.title}</h3>
                  </div>

                  <div className="excerpt">
                    <p>{article.excerpt.excerpt}&hellip;</p>
                  </div>

                  <div className="thumbnail">
                    <GatsbyImage
                      image={getImage(article.image)}
                      alt={article.title}
                    />
                  </div>

                  <div className="read-more">
                    <span className="button">Read more</span>
                  </div>
                </Link>
              )
            })}

            <div className="pagination">
              <div className="prev">
                <Link
                  to={isFirst ? location.pathname : pagePath + prevPage}
                  rel="prev"
                  className={isFirst ? ' disabled' : ''}
                >
                  ← Previous
                </Link>
              </div>

              <div className="pages">
                {Array.from({ length: numPages }, (_, i) => {
                  const pageNum = i + 1

                  return (
                    <Link
                      key={`pagination-${pageNum}`}
                      to={pagePath + (i === 0 ? '' : pageNum + '/')}
                      className={currentPage === pageNum ? 'current' : ''}
                    >
                      {pageNum}
                    </Link>
                  )
                })}
              </div>

              <div className="next">
                <Link
                  to={isLast ? location.pathname : pagePath + nextPage}
                  rel="next"
                  className={isLast ? ' disabled' : ''}
                >
                  Next →
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query enNewsListQuery($skip: Int!, $limit: Int!) {
    allContentfulAktuelles(
      filter: {
        company: { elemMatch: { name: { eq: "GE-T" } } }
        node_locale: { eq: "en" }
      }
      sort: { fields: publishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        slug
        publishedAt
        image {
          gatsbyImageData(
            height: 576
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 90
            aspectRatio: 1
          )
        }
        excerpt {
          excerpt
        }
      }
    }
  }
`

export default NewsPage
